@font-face {
  font-family: Alert;
  src: url(./assets/fonts/font1.ttf);
}
@font-face {
  font-family: Pixel;
  src: url(./assets/fonts/editundo.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    display: none;
  }
}
