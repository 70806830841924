@mixin mobile {
  @media (max-width: 668px) {
    @content;
  }
}

@mixin smallphone {
  @media (max-width: 650px) and (max-height: 750px) {
    @content;
  }
}

.light {
  color: #000000;
}

.big {
  font-size: 2.5rem !important;
}

.small {
  font-size: 1rem;
  @include mobile {
    font-size: 1rem !important;
  }
}

.m1 {
  @include mobile {
    font-size: 2rem !important;
  }
}

.plat {
  @include mobile {
    font-size: 1.8rem !important;
  }
}

.dark {
  color: #ffffff;
}

.code {
  font-family: Alert;
  color: #a7000f;
  z-index: 1;
  @include mobile {
    font-size: 1.5rem;
  }
}

.pixel {
  font-family: Pixel;
  @include mobile {
    font-size: 2rem;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #000000;
  transition: all 0.25 ease-in-out;
  overflow: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  .videoTag {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 0;
    opacity: 0.8;
    mix-blend-mode: difference;
  }
  .artists,
  .labels,
  .form form,
  .sendlink {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50vh;
  }
  .artists,
  .labels {
    scroll-snap-align: start;
    @include mobile {
      height: 100vh;
    }
  }
  .sendlink {
    background-color: rgba(255, 255, 255, 0.1);
    @include mobile {
      height: 100vh;
    }
    .videoTag {
      height: 50vh;
      opacity: 0.2;
      @include mobile {
        height: 100vh;
      }
    }
    .msg {
      width: 100%;
      display: flex;
      flex-direction: column;
      .join {
        @include mobile {
          font-size: 1.5rem;
        }
      }
    }
    .linkinp {
      width: 100%;
      height: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      @include mobile {
        height: 70vh;
      }
      #linkform {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        @include mobile {
          height: 100%;
        }
        .inputs {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          @include mobile {
            flex-direction: column;
            height: 100%;
          }
          .thanks,
          .sending {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              width: 90vw;
            }
          }
          div {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            @include mobile {
              flex-direction: column;
              height: 80%;
            }
            .inner {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .icon {
                align-items: center;
                justify-content: center;
              }
              .inperr {
                display: flex;
                flex-direction: column;
                align-items: center;
                input {
                  font-size: 1.7rem;
                  border: none;
                  transition: all 0.2s ease-in-out;
                  width: 500px;
                  @include mobile {
                    width: 90vw !important;
                  }
                  &.dark {
                    background-color: rgba(51, 51, 51, 0);
                    border-bottom: 1px solid #ffffff;
                    color: #ffffff;
                    &:focus {
                      outline: none;
                      border-bottom: 3px solid #ffffff;
                    }
                  }
                  &.light {
                    background-color: rgba(255, 255, 255, 0.5);
                    border-bottom: 1px solid #000000;
                    color: #000000;
                    &:focus {
                      outline: none;
                      border-bottom: 3px solid #000000;
                    }
                  }
                }
              }
            }
          }
        }
        .button {
          justify-content: center;
          align-items: center;
          height: 10% !important;

          input {
            &.dark {
              font-size: 2rem;
              border: none;
              background-color: rgba(51, 51, 51, 0.5);
              border-bottom: 1px solid #ffffff;
              color: #ffffff;
              transition: all 0.2s ease-in-out;
            }
            &.light {
              font-size: 2rem;
              border: none;
              background-color: rgba(255, 255, 255, 0.5);
              border-bottom: 1px solid #000000;
              color: #000000;
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }
  .artists {
    .list {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      @include mobile {
        height: 80vh;
        flex-direction: column;
      }
      .artist {
        height: 100%;
        width: 100%;
        &:hover {
          .artistlink {
            h1 {
              opacity: 1;
            }
          }
          .artistsimg {
            img {
              opacity: 1;
            }
          }
        }
        .artistlink {
          text-decoration: none;
          transition: all 0.2s ease-in-out;
          h1 {
            position: relative;
            opacity: 0.8;

            @include mobile {
              z-index: 1;
              position: absolute;
              font-size: 2rem;
              color: #ffffff;
            }
          }
          &.dark {
            color: #ffffff;
          }
          &.light {
            color: #000000;
          }
        }
        .artistsimg {
          width: 100%;
          height: 250px;
          @include smallphone {
            height: 90px !important;
          }
          @include mobile {
            height: 115px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.8;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }
    }
  }
  .labels {
    background-color: rgba($color: #000000, $alpha: 0.2);
    @include mobile {
      justify-content: center;
      display: flex;
    }
    .list {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      @include mobile {
        flex-direction: column;
      }
      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include mobile {
          flex-direction: row-reverse;
        }
        a {
          color: inherit;
          text-decoration: none;
          transition: all 0.2s ease-in-out;
          @include mobile {
            z-index: 1;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        img {
          width: 13vw;
          opacity: 0.8;
          transition: opacity 0.2s ease-in-out;

          @include mobile {
            position: absolute;
            z-index: 0;
            width: 240px;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 2rem;
    height: 90vh;
    @include mobile {
      flex-direction: column;
    }
    .contact {
      height: 100%;
      width: 50vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a {
        background-color: rgba(#636363, 0.5);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: rgba(#636363, 0.9);
        }
      }
      @include mobile {
        width: 100vw;
        height: 15vh;
      }
    }
    .thanks {
      display: flex;
      flex-direction: column;
    }
    &.light {
      .name,
      .email,
      .message,
      .button {
        input,
        textarea {
          background-color: rgba(#ffffff, 0.5);
          border-bottom: 1px solid #000000;
          color: #000000;
          &:focus {
            outline: none;
            border-bottom: 3px solid #000000;
          }
        }
      }
    }
    form {
      height: 100%;
      width: 50vw;
      @include mobile {
        width: 100vw;
        height: 85vh;
      }
      .name,
      .email,
      .message,
      .button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        input,
        textarea {
          font-size: 2rem;
          border: none;
          background-color: rgba(#000000, 0.5);
          border-bottom: 1px solid #ffffff;
          color: #ffffff;
          transition: all 0.2s ease-in-out;
          &:focus {
            outline: none;
            border-bottom: 3px solid #ffffff;
          }
        }
      }
    }
  }
  .App-logo {
    height: 15vmin;
    z-index: 1;
    @include mobile {
      width: 90vw;
      height: 100%;
    }
  }
  &.light {
    background-color: #ffffff !important;
    transition: all 0.25 ease-in-out;
    .App-header {
      color: #000000;
    }
  }
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow: hidden;

    .rapper {
      position: absolute;
      width: 500px;
      overflow: hidden;
      @include mobile {
        width: 390px;
        top: 25px;
      }
      top: 0px;
      z-index: 0;
    }
    .mic {
      position: absolute;
      top: 60px;
      width: 500px;
      @include mobile {
        width: 390px;
        top: 50px;
      }
    }
    .broccoli {
      height: 10vmin;
      @include mobile {
        height: 25vmin;
        bottom: 0;
        top: auto;
      }
      position: absolute !important;
      top: 0;
      left: 0;
      opacity: 0.5;
      transition: all 0.25s ease-in-out;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
  .btmlogocnt {
    position: sticky;
    bottom: 0;
    left: 0;
    height: 10vh;
    
    .btmlogo {
      left: 10px;
      width: 300px;
      @include mobile {
        width: 170px;
      }
    }
  }
}

.animate__fadeInDown {
  animation-delay: 0.5s;
}

.animate__fadeIn,
.animate__fadeOut {
  animation-delay: 0.45s;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  &.appear {
    opacity: 1;
  }
}
.fade-in-delayed {
  opacity: 0;
  transition: opacity 1s ease-in-out !important;
  &.appear {
    opacity: 1;
  }
}
.fade-in-late {
  opacity: 0;
  transition: opacity 2.7s ease-in-out !important;
  &.appear {
    opacity: 1;
  }
}
